import React, { useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import { Button } from 'antd';
import moment from 'moment'
import BillingService from '../../../services/billing';
import "./Print.css"

export const PrintReedem = () => {

    const params = useParams();
    const [client, setClient] = useState();
    const [redeem, setReedem] = useState();
    const [info, setInfo] = useState();
    const [date, setDate] = useState();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        let data =  {id: params.id};
        BillingService.getReedem(data).then(
          (response) => {
            console.log(response);
            setClient(response.data.client);
            setReedem(response.data.redeemLog);
            setInfo(response.data.redeem);
            setDate(moment(new Date(response.data.redeemLog.fecha)).format('DD-MM-YYYY HH:MM:s'));
            console.log()
            setIsLoaded(true);
          },
          (error) => {
              // console.log(error.response.data.message)
          }
        );
    }, [])
    

    return (
        <>
            { isLoaded &&
            <div className='print' id="print">
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src="https://coopegas.com/wp-content/uploads/2020/10/logo.jpg" alt="Logo"/>

                </div>
                <p className="centered bold">Cooperativa De Producción y Servicios Múltiples La Económica, INC</p>
                <p className="centered">RNC: 4-0105258-1</p>
                <p className="centered bold">Canje de Puntos  </p>
                
                

                <table>
                    <thead>
                        <tr>
                            <th className="description ">No. Canje.</th>
                            <th className="description"></th>
                            {/* <th className="description">Moneda</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="description centered">FTC{redeem.id}</td>
                            <td className="description centered"></td>
                            {/* <td className="description centered">DOP</td> */}
                        </tr>
                    </tbody>
                </table>
                <br/>
                <p> <b>Cliente: </b>{client.nombre + ' '} {(client.apellido !== null) ? client.apellido : ''}</p>
                <p><b>Fecha:</b> {date} </p>
                {/* <p><b>Hora:</b> {date.getHours() + ':' + date.getMinutes()}</p> */}

                <br/>
                <table>
                    <thead>
                        <tr>
                            <th className="description">Cant.</th>
                            <th className="description">Descripción</th>
                            <th className="description">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="description centered">{redeem.canjeados}</td>
                            <td className="description centered">PUNTO</td>
                            <td className="description centered">{redeem.canjeados}</td>
                        </tr>

                        <tr>
                            <td className="description "></td>
                            <td className="description centered">TOTAL</td>
                            <td className="description centered">{redeem.canjeados}</td>
                        </tr>
                    </tbody>
                </table>
                
                <br/>
                <p className="centered">_________________________________</p>
                
                <p className="centered">Firma</p>
                <br/>
                <p className="centered">_________________________________</p>
                <p className="centered">Cédula</p>

                <br/>
                <p className="centered">Puntos restantes: <b>{ (client.codigo === '22222') ? 0 : Number(info.restantes).toFixed(2)}</b> </p>
                <hr/>
                <p className="centered">Gracias por su compra</p>
                <br/>
                <Button type='primary' className='print-btn' onClick={() => window.print()} >Imprimir</Button>
            </div> }
        </>
    )
}
