import React, {useState, useEffect} from 'react'
import { Button, Form, Input, InputNumber, Typography, message, Select, Switch } from 'antd';
import { ScreenHeader } from '../../UI/ScreenHeader.component';
import PromotionService from '../../../services/promotion.service';
const { TextArea } = Input;
const { Option } = Select;

const { Title } = Typography;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

export const  EditPromotionForm = ({promotion, promotionID, branches}) => {

  const selectStyle = {display: 'block', width:'100%'}

  const [form] = Form.useForm();

  const renderProfileOptions = (branches) => {
    const sortedBranches = branches?.sort((a, b) => a.sucursal.localeCompare(b.sucursal));
    return sortedBranches.map(branch => <Option key={branch.id} value={branch.id} >{branch.sucursal}</Option> );
  };


  const handleChange = (value) => {
    form.setFieldsValue({ id_sucursal: value })
  };



    const onFinish = (data) => {
        Object.assign(data, {id: promotionID});
        console.log(data);
        PromotionService.editPromotion(data).then(
          (response) => {
            message.success("Promoción editada correctamente");
          },
          (error) => {
              // console.log(error.response.data.message)
          }
        );
    };

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };

  return (
    <Form 
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={promotion}
    >
        <ScreenHeader style={{width: '400px;'}} >
            <Title>Editar Promoción</Title>
        </ScreenHeader>

      <Form.Item name='promocion' label="Nombre Promoción">
        <Input />
      </Form.Item>
      <Form.Item name='descripcion' label="Descripción">
        <TextArea rows={3}/> 
      </Form.Item>
      <Form.Item name='valor' label="Valor">
        <Input type='number' />
      </Form.Item>
      <Form.Item name='estatus' label="Estado">
         <Switch onChange={onChange} checked={promotion.estatus}/>
      </Form.Item>
      <Form.Item name='cant_min_canje' label="Cant. Min. Canje">
        <Input type='number' />
      </Form.Item>

      <Form.Item name='id_sucursal' label="Sucursal"
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      >
        <Select defa onChange={handleChange} style={selectStyle} >
          {renderProfileOptions(branches)}                        
        </Select>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 8,
        }}
      >
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};