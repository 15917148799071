import React, {  } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import { Print } from '../components/Dashboard/Billing/Print';
import { PrintReedem } from '../components/Dashboard/Redeem/PrintReedem';
import { AuthRoutes } from './AuthRoutes';
import { DashboardRoutes } from './DashboardRoutes';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
// DashboardRoutes

export const AppRouter = () => {



    
    return (
        // <Router>
            <div>
                <Routes>

                    <Route 
                        path="/*" 
                        element={
                        <PublicRoute>
                            <AuthRoutes />
                        </PublicRoute>
                        }
                    />

                    <Route
                        path='/print/factura/:id'
                        element={<Print/>}
                    
                    />

                    <Route
                        path='/print/canje/:id'
                        element={<PrintReedem/>}
                    
                    />

                    <Route 
                        path="app/*" 
                        element={
                        <PrivateRoute>
                            <DashboardRoutes/>
                        </PrivateRoute>
                        }
                    />
                    
                </Routes>
            </div>
        // </Router>
    )
} 
