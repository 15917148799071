import { Form, Input, Button, Row, Typography, message} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './Auth.css';
import AuthService from '../../services/auth.service';
import { useApp } from '../../context/app-context';

const {Title} = Typography;

export const Login = () => {
    
    let navigate = useNavigate();
    const { dispatch } = useApp();

    const onFinish = (data) => {
        const {username, password} = data;
        
        AuthService.login(username, password).then(
            (response) => {
                // (response.type === 'adm' ? navigate("/dashboard") : navigate("/facturacion") )
                message.success("Bienvenido");
                dispatch({type: 'update-user', payload: response})
                dispatch({type: 'login', payload: true})

                
            },
            (error) => {
                // console.log(error.response.data.message)
                message.error("Credenciales no validas");
            }
        );
    };

    return (
        <>
            <Row className="login-container">
                <Form
                    name="login"
                    className="form-container"
                    onFinish={onFinish}
                    size='default'
                    autoComplete='off'
                >   
                    <Title level={5}>Inicia Sesión</Title>
                    
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: "Usuario requerido" }]}
                    >
                        <Input 
                            prefix={<UserOutlined/>}
                            placeholder="Usuario"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: "Contraseña requerida" }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined/>}
                            type="password"
                            placeholder="Contraseña"
                            autoComplete='off'
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block shape='round' className="login-form-button">Iniciar sesión</Button>
                        {/* Or <a href="">register now!</a> */}
                    </Form.Item>
                </Form>
            </Row>
        </>
    )
}
