import React, {useState, useEffect} from 'react'
import { Button, Form, Input, InputNumber, Typography, Select, message } from 'antd';
import { ScreenHeader } from '../../UI/ScreenHeader.component';
import ClientService from '../../../services/client.service';
const { Option } = Select;

const { Title } = Typography;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

export const  EditInformationForm = ({client, clientID, branches}) => {

  const [form] = Form.useForm();

  const selectStyle = {display: 'block', width:'100%',}


  const renderProfileOptions = (branches) => {
      const sortedBranches = branches?.sort((a, b) => a.sucursal.localeCompare(b.sucursal));
      return sortedBranches.map(branch => <Option key={branch.id} value={branch.id} >{branch.sucursal}</Option> );
  };

  const handleChange = (value) => {
    form.setFieldsValue({ id_sucursal: value })
  };

  const onFinish = (data) => {
    Object.assign(data, {id: clientID});
    ClientService.editClient(data).then(
      (response) => {
        message.success("Cliente editado correctamente");
      },
      (error) => {
          // console.log(error.response.data.message)
      }
    );
  };

  return (
    <Form 
      form={form}
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={client}
    >
        <ScreenHeader style={{width: '400px;'}} >
            <Title>Editar cliente</Title>
        </ScreenHeader>

      <Form.Item
        name="codigo"
        label="Código"
        
      >
        <Input disabled={true} rows={6}/>
      </Form.Item>

      <Form.Item name='cedula' label="Cedula" 
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='nombre' label="Nombre"
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      
      >
        <Input />
      </Form.Item>
      <Form.Item name='apellido' label="Apellido"
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='telefono' label="Teléfono"
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='placa' label="No. Placa"
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='vehiculo' label="Vehiculo"
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='tipo_vehiculo' label="Tipo Vehiculo"
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='id_sucursal' label="Sucursal"
        rules={[
          {
              required: true,
              message: 'Campo requerido',
          },
        ]}
      >
        <Select defa onChange={handleChange} style={selectStyle} >
          {renderProfileOptions(branches)}                        
        </Select>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 8,
        }}
      >
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};