import React, {useState, useEffect} from 'react'
import { Button, Form, Input, InputNumber, Typography, message } from 'antd';
import { ScreenHeader } from '../../UI/ScreenHeader.component';
import UserService from '../../../services/user.service';

const { Title } = Typography;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

export const  EditInformationForm = ({user}) => {

  const onFinish = (data) => {
    Object.assign(data, {id: user.id});

    UserService.editUser(data).then(
      (response) => {
        message.success("Cliente editado correctamente");
      },
      (error) => {
          // console.log(error.response.data.message)
      }
    );
  };

  return (
    <Form 
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={user.username}
    >
        <ScreenHeader style={{width: '400px;'}} >
            <Title>Editar usuario</Title>
        </ScreenHeader>

      <Form.Item
        name="username"
        label="Usuario"
        initialValue={user.username}
        rules={[
          {
            type: 'text',
          },
        ]}
      >
        <Input disabled={true} rows={6}/>
      </Form.Item>

      {/* <Form.Item name='email' label="Correo electrónico">
        <Input />
      </Form.Item> */}
      <Form.Item name='password'  label="Nueva contraseña">
        <Input required={true}  type='password' />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 8,
        }}
      >
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};