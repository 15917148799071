import React, {useCallback} from 'react'
import { Layout, Row, Col, } from 'antd';
import { Routes, Route, Link } from 'react-router-dom';
import AuthVerify from '../common/AuthVerify';
import AuthService from '../services/auth.service';
import { AuthLayout } from '../components/Auth/layouts/AuthLayout';
import { Login } from '../pages/Auth/Login';
import Profile from '../components/Profile';


const layoutStyle = {
    backgroundColor: 'var(--primary-color)',
    minHeight: '100vh',
    height: '100%',
    padding: '5% 0',
};

const contentStyle = {
    backgroundColor: 'white', 
    padding: 25, 
    borderRadius: 10, 
};

export const AuthRoutes = () => {

    // const { user: currentUser } = useSelector((state) => state.auth);
    // const dispatch = useDispatch();

    // const logOut = useCallback(() => {
    //     dispatch(AuthService.logout());
    // }, [dispatch]);
    

    return (
        <>
        <Layout style={layoutStyle}>
            <Row justify='center' align='middle'>
                <Col style={contentStyle}>
                    <AuthLayout/>
                    <div className="">
                        {/* Unauthenticated routes */}
                        <Routes>
                            <Route path="/" element={ <Login /> } />
                        </Routes>
                    </div> 
                </Col>
            </Row>
        </Layout>
        </>
    )
}
