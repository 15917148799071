import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : "http://localhost:5000/api/";


const getClients = (data) => {
  return axios.get(API_URL + "clients", {headers: authHeader(), params: data});
};

const getClient = (data) => {
  return axios.get(API_URL + "clients/" + data.id, {headers: authHeader(), params: data});
};

const getClientByCode = (data) => {
  return axios.get(API_URL + "clients/code/" + data.code, {headers: authHeader(), params: data});
};

const save = (user) => {
  return axios
  .post(API_URL + "clients/save", {
      ...user
  },)
  .then((response) => {
      return response
  });
}

const editClient = (client) => {
  return axios
  .post(API_URL + "clients/" + client.id, {
      ...client
  },)
  .then((response) => {
      return response
  });
} 


const ClientService = {
  getClients,
  getClient,
  editClient,
  getClientByCode,
  save
};

export default ClientService;
