import React, { useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Select, DatePicker, Button, Row, Steps, Space, TreeSelect, message, Input, Switch, InputNumber  } from 'antd';
import moment from "moment";
import "./Redeem.css"
import ClientService from '../../../services/client.service';
import BillingService from '../../../services/billing';
import { Scanner } from '../QR/Scanner';
// import { Print } from './Print';
const { Option } = Select;


const { Step } = Steps;

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const Redeem = ({dataSource, ...props}) => {

    let navigate = useNavigate();
    const today = new Date();
    const [form] = Form.useForm();
    const [branches, setBranches] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [currentStep, setCurrentStep] = useState(0)
    const [isScanning, setIsScanning] = useState(false);
    const [redeemable, setRedeemable] = useState(false);
    const [enablePrinting, setEnablePrinting] = useState(false);
    const [invoiceID, setInvoiceID] = useState();
    const [promotion, setPromotion] = useState();
    


    const renderBranchesOptions = (branches) => {
        const sortedBranches = branches?.sort((a, b) => a.sucursal.localeCompare(b.sucursal));
        return sortedBranches.map(branch => <Option key={branch.id} value={branch.id} >{branch.sucursal}</Option> );
    };


   
    useEffect(() => {
        setBranches(dataSource.rows);
        disableReedem();
      
    }, [enablePrinting, promotion]);

    const handlePrint = () => {
        navigate('/print/canje/' + invoiceID)
    }

    const disableReedem = () => {
        if(today.getDay() === 2 || today.getDay() === 5) {
            setRedeemable(true)
        } 
    }


    const onCancel = () => {
        form.resetFields(); 
        setEnablePrinting(false)
    }

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };

    const handleSubmit = (data) => {
        setPaymentMethod(data)
        form.submit()
    }


    const onFinish = (data) => {
        Object.assign(data, {metodo_pago: paymentMethod});

        BillingService.reedem(data).then(
            (response) => {
                if(!response.data.ok) {
                    message.error(response.data.message)
                }
                if( response.data.redeem ) {
                    setInvoiceID(response.data.redeemLog.id);
                    // form.resetFields()
                    setEnablePrinting(true)
                    message.success("Canje registrado correctamente");
                }
            },
            (error) => {
                console.log(error.data)
                
            }
        )
    }

    const onValuesChange = (changedValues, allValues) => {
      
    }

    const findClient = (code)  => {
        ClientService.getClientByCode({code}).then(
            (response) => {
                console.log(response.data)
                if(response.data.client) {
                    console.log(response.data.client)
                    message.success(`Cliente ${response.data.client.nombre}`)
                    setPromotion(response.data.promotion);
                    form.setFieldsValue({ codigo_cliente: code, cliente: response.data.client.nombre, id_sucursal: response.data.client.id_sucursal, puntos: (response.data.client.codigo === '22222' ? 0 : response.data.redeem.restantes) })
                    setIsScanning(false);
                } else {
                    message.error("Cliente no encontrado")
                }
            },
            (error) => {
                console.log(error.data)
                
            }
        )
    }

    const scan = () => {
        setIsScanning(true)
    }

    return (
        <>
            <Form 
                form={form} 
                name="scheduler-form" 
                layout="vertical" 
                className='scheduler'
                onFinish={(values) => onFinish(values)}
                initialValues={{
                    dateTime: moment().add(2, 'minutes'), 
                    site: '',
                    canje: 0
                    // devices: [],
                    
                }}
                onValuesChange={(changedValues, allValues) => onValuesChange(changedValues, allValues)}
                >
                

                { isScanning && <Scanner findClient={findClient} />}
                {!isScanning && <Button type='primary' onClick={ () => scan()} >ESCANEAR</Button>}
                {/* <Button type='' onClick={ () => findClient(80936)} >CONSUMIDOR FINAL</Button> */}


                <Steps current={currentStep} progressDot>
                    <Step description={
                        <Form.Item
                            label='Código '
                            name="codigo_cliente"
                            rules={[
                                { 
                                    required: true, 
                                    message: '*Por favor seleccione un cliente', 
                                },
                            ]}
                            >
                        
                            <Input disabled={true}/>
                            {/* <Input onKeyDown={ (e) => findClient(e)}/> */}
                        </Form.Item>
                        }/>

               

                    <Step description={
                        <Form.Item 
                            name="id_sucursal"
                            label="Planta"
                            rules={[
                                { 
                                    required: true, 
                                    message: 'Please select a device', 
                                },
                            ]}
                        > 

                            <Select disabled={true} placeholder="Seleccionar planta"  onChange={()=> setCurrentStep(2)}>
                               {renderBranchesOptions(branches)}
                            </Select>
                        </Form.Item>
                    }/>

                    <Step description={
                        <Form.Item 
                            name="cliente"
                            label="Cliente"
                            rules={[
                                { 
                                    required: true, 
                                    message: 'Please select a device', 
                                },
                            ]}
                        > 

                            <Input disabled={true} />
                        </Form.Item>
                    }/>

                    <Step description={
                        <Form.Item 
                            name="puntos"
                            label="Puntos"
                            rules={[
                                { required: false, message: 'Please select a file', },
                            ]}
                            >
                           <Input disabled={true}/>
                        </Form.Item>
                    }/>


                    <Step description={
                        <Form.Item 
                            name="canje"
                            label="Canjear"
                            rules={[
                                { required: true, message: 'Inserta cantidad valida', },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if(value <= getFieldValue('puntos')){
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('No tienes puntos sufucientes para canjear esta cantidad'))
                                    }
                                }),
                            ]}
                            >
                           <InputNumber  type={'number'}  min={promotion?.cant_min_canje} max={promotion?.cant_min_canje}/>
                        </Form.Item>
                    }/>
             
                    </Steps>



             
            


                    

                    




                <Row   >
                    <Space style={{overflowX: 'auto', display: 'flex', justifyContent: 'center'}}>
                        <Form.Item>
                            <Button onClick={() => onCancel()}>Nuevo</Button>
                        </Form.Item>
                        
                        { !enablePrinting && <Form.Item name="metodo_pago">
                            <Button type="primary" onClick={ () => handleSubmit('efectivo')} >Canjear</Button>
                        </Form.Item>
                        }

                        { enablePrinting && <Form.Item>
                            <Button type='primary' onClick={() => handlePrint() }>Imprimir</Button>
                        </Form.Item>
                        }
                    </Space>
                </Row>   
                
            </Form>
           
        </>
    )
}