import axios from "axios";
import authHeader from "./auth-header";


const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : "http://localhost:5000/api/";


const getUsers = (data) => {
  console.log("data:", data)
  return axios.get(API_URL + "users", {headers: authHeader(), params: data});
};


const getUser = (data) => {
  console.log("data:", data)
  return axios.get(API_URL + "users/" + data.id, {headers: authHeader(), params: data});
};



const save = (user) => {
  return axios
  .post(API_URL + "users/save", {
      ...user
  },)
  .then((response) => {
      return response
  });
}


const editUser = (user) => {
  return axios
  .post(API_URL + "users/" + user.id, {
      ...user
  },)
  .then((response) => {
      return response
  });
} 



const UserService = {
  getUsers,
  save,
  getUser,
  editUser
};

export default UserService;
