import React from 'react'

export const Section = ({type, ...props}) => {
    
    const main = {
        padding: "30px",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        marginBottom: "30px",
        overflowX: "auto",
        ...props.style,
    };

    const secondary = {
        padding: "20px",
        backgroundColor: "var(--gray-3)",
        border: '1px solid var(--gray-5)',
        borderRadius: "5px",
        marginBottom: "30px",
        overflowX: "auto",
        ...props.style,
    }

    return (
        <>        
            <div className="screen__section" {...props} style={type === 'secondary' ? secondary : main}>
                {props.children}
            </div>
        </>
    )
}

Section.defaultProps = {
    type: 'main',
}
