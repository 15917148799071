import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : "http://localhost:5000/api/";



const getBranches = (data) => {
  return axios.get(API_URL + "branches", {headers: authHeader(), params: data});
};

const getBranch = (data) => {
  return axios.get(API_URL + "branches/" + data.id, {headers: authHeader(), params: data});
};

const save = (branch) => {
  return axios
  .post(API_URL + "branches/save", {
      ...branch
  },)
  .then((response) => {
      return response
  });
}

const editBranch = (branch) => {
  return axios
  .post(API_URL + "branches/" + branch.id, {
      ...branch
  },)
  .then((response) => {
      return response
  });
} 


const BranchService = {
  getBranches,
  getBranch,
  editBranch,
  save
};

export default BranchService;
