import React from 'react';
import { Typography, Row, Col, Space, Button, } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './Modal.css';

const { Title } = Typography;

export const Modal = (props) => {

    const {
        visible, 
        setVisible, 
        title,
        titleLevel,
        icon,
        color,
        overlay,
        children,
        action,
        justifyTitle,
        actionGroup,
        onOk,
        okText,
        okProps,
        cancelProps,
        cancelText,
        width,
        extra,
        extraIcon,
        extraType,
        extraOnClick,
        ...other } = props;

    let display = visible ? 'block' : 'none';

    const modalStyle = {
        width: width,
        ...other.style,
    }
    
    const modalIcon = icon ? <span className="modal__icon" style={{color: `${color}`}}>{icon}</span> : '';

    const displayActionGroup = () => {
        if (actionGroup){
            let okButton = <Button type="primary" onClick={onOk} {...okProps}>{okText ? okText : 'Submit'}</Button>;
            return (
                <Row className="modal__action-group" justify="end">
                    <Space>
                        <Button onClick={() => setVisible(false)} {...cancelProps}>{cancelText ? cancelText : 'Cancel'}</Button>
                        {okText ? okButton : ''}
                    </Space>
                </Row>
            );
        }
    }

    const onClose = () => {
        setVisible(false);
    };

    return(
        <>
            <div className="modal__container" style={{display: display}}>
            <div className="modal__overlay" style={{backgroundColor: overlay ? overlay : 'rgba(0,0,0,0.5)'}} onClick={onClose}></div>
                <div {...other} className={`${other.className || ``} modal`} style={modalStyle}>
                    <CloseOutlined className="modal__icon--close" onClick={onClose}/>
                    <Row className="modal__header" justify={justifyTitle} style={{marginBottom: 10, marginTop: 20}}>
                        {modalIcon}
                        <Row justify='space-between'>
                            <Title className='--default' style={{margin: 0}} level={titleLevel}>{title}</Title>
                            {extra ? 
                                <Button icon={extraIcon} type={extraType} onClick={extraOnClick || undefined}>{extra}</Button>
                            : null}
                        </Row>
                    </Row>
                    <div className="modal__content">
                        {visible ? children : ''}
                    </div>
                    {displayActionGroup()}
                </div>
            </div>
        </>
    );
}

Modal.defaultProps = {
    actionGroup: true, 
    visible: false, 
    setVisible: false,
    titleLevel: 3,
    width: 400,
    extra: false,
    extraIcon: '',
    extraType: 'primary',
}