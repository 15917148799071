import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import { NavBar } from '../components/Dashboard/layouts/NavBar'
import { useApp } from '../context/app-context';
import Profile from '../components/Profile';
import { UserScreen } from '../pages/Dashboard/UserScreen';
import { ClientScreen } from '../pages/Dashboard/ClientScreen';
import { PointScreen } from '../pages/Dashboard/PointScreen';
import { EditUserAccountScreen } from '../pages/Dashboard/EditUserAccountScreen';
import { EditClientAccountScreen } from '../pages/Dashboard/EditClientAccountScreen';
import { BranchScreen } from '../pages/Dashboard/BranchScreen';
import { PromotionScreen } from '../pages/Dashboard/PromotionScreen';
import { BillingScreen } from '../pages/Dashboard/BillingScreen';
import { EditBranchScreen } from '../pages/Dashboard/EditBranchScreen';
import { EditPromotionScreen } from '../pages/Dashboard/EditPromotionScreen';
import { Print } from '../components/Dashboard/Billing/Print';
import { ReedemScreen } from '../pages/Dashboard/RedeemScreen';
import { VisitScreen } from '../pages/Dashboard/VisitScreen';
import { ReedemPointScreen } from '../pages/Dashboard/RedeemPointScreen';
import { RedeemCustomerScreen } from '../pages/Dashboard/RedeemCustomerScreen';
import { InvoiceScreen } from '../pages/Dashboard/InvoiceScreen';


export const DashboardRoutes = () => {
    
    const {state: {user}} = useApp();
    return (
        <>
            <Layout style={{minHeight: "100vh"}}>
                <NavBar/>
                <Routes>
                    {/* Authenticated routes */}
                    <Route path="/" element={ <UserScreen /> } />
                    <Route path="/clientes" element={ <ClientScreen /> } />
                    <Route path="/puntos" element={ <PointScreen /> } />
                    <Route path="/plantas" element={ <BranchScreen /> } />
                    <Route path="/promociones" element={ <PromotionScreen /> } />
                    <Route path="/facturacion" element={ <BillingScreen /> } />
                    <Route path="facturacion/print" element={ <Print /> } />
                    <Route path="canje/print" element={ <Print /> } />
                    <Route path="/usuario/editar/:id" element={ <EditUserAccountScreen /> } />
                    <Route path="/cliente/editar/:id" element={ <EditClientAccountScreen /> } />
                    <Route path="/planta/editar/:id" element={ <EditBranchScreen /> } />
                    <Route path="/promocion/editar/:id" element={ <EditPromotionScreen /> } />
                    <Route path="/reportes/canjes" element={ <ReedemScreen /> } />
                    <Route path="/reportes/visitas" element={ < VisitScreen/> } />
                    <Route path="/reportes/facturas" element={ < InvoiceScreen/> } />
                    <Route path="/reportes/canje/clientes" element={ < RedeemCustomerScreen/> } />
                    <Route path="/canje" element={ < ReedemPointScreen/> } />
                </Routes>
            </Layout>
        </>
    )
}
