import React, { useState, useEffect } from 'react'
import { Typography, Input, Row, Col, Button, Space, message } from 'antd';
import { Screen } from '../../components/UI/Screen.component';
import { Container } from '../../components/UI/Container.component';
import { ScreenHeader } from '../../components/UI/ScreenHeader.component';
import { Section } from '../../components/UI/Section.component';
import PointService from '../../services/point.service';


const { Title } = Typography;


export const PointScreen = () => {

    const [point, setPoint] = useState(0)


    useEffect(() => {
        PointService.getPoint().then(
            (response) => {
                setPoint(response.data.point.valorpuntos)
            },
            (error) => {
                console.log(error.response.data.message)
            }
        );

       
    }, [])


    const handleUpdate =  () => {
        PointService.update(point).then(
            (response) => {
                message.success("Cambios guardados exítosamente.")
            },
            (error) => {
                console.log(error.response.data)
            }
        )
    }
    



    return(
        <>
            <Screen id="access-screen">
                <Container>
                    <ScreenHeader>
                        <Title>Administración de Precio Gas</Title>
                    </ScreenHeader>
                    <Section>
                        <Row justify="space-between" style={{gap:"10px",}}>
                            <Col>
                                <Space size="small">
                                    <p>Precio:</p>
                                    <Input type='number' placeholder="" onChange={(e) => setPoint(e.target.value)} value={point} />
                                    <Button type='primary' onClick={() => handleUpdate() }>Actualizar</Button> 
                                </Space>
                            </Col>
                        </Row>
                    </Section>
                </Container>
            </Screen>
        </>
    )
}