import React, {useEffect, useState} from 'react';
import { Form, Input, Select, Button, Row, message as notification, Typography  } from "antd";
import { UserAddOutlined, EyeTwoTone, EyeInvisibleOutlined} from '@ant-design/icons';
import BranchService from '../../../services/branch.service';

const { Option } = Select;

export const AddNewBranchForm = ({ setChange, setVisible, ...props }) => {

    const [form] = Form.useForm();

    const selectStyle = {display: 'block', width:'100%',}

    const onFinish = (data) => {     
        BranchService.save(data).then(
            (response) => {
                notification.success("Sucursal agregada éxitosamente.")
                setVisible(false);
                setChange((current) => !current);
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handleChange = (value) => {
        form.setFieldsValue({ id_sucursal: value })
    };

    const onCancel = () => {
        form.resetFields();
        setChange(false);
    }

    return(
        <>
            <Form
                form={form}
                name='add-new-client-form'
                layout='vertical'
                colon={true}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item 
                    label="Nombre"
                    name="sucursal"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>
                <Form.Item 
                    label="Descripción"
                    name="descripcion"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>
                
                <Form.Item>
                    <Row justify="end" style={{gap: 8}}>
                        <Button onClick={() => onCancel()}>Cancel</Button>
                        <Button type='primary' htmlType='submit' icon={<UserAddOutlined/>}>Guardar</Button>
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
}