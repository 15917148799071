import React, { useState, useEffect, } from 'react'
import { Link } from 'react-router-dom';
import { Table, Button, Space, Alert, message, Typography, Row, Form, Input, } from 'antd';
import { DeleteOutlined, EditOutlined, WarningFilled} from '@ant-design/icons';
import { Modal } from '../../UI/Modal/Modal.component';

const { Text, Paragraph } = Typography;

export const UserTable = ({data, search, ...props}) => {

    const [dataSource, setDataSource] = useState(data.rows);
    const [totalRecords, setTotalRecords] = useState(data.count);
    const [currentRow, setCurrentRow] = useState('');

    useEffect(() => {
        setDataSource(data.rows);
        setTotalRecords(data.count)
    }, [data])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
        },
        {
            title: 'Usuario',
            dataIndex: 'username',
            fixed: 'left',
            width: 300,
            render: (record) => {
                return record.charAt(0).toUpperCase() + record.substring(1);
            },
            sorter: (a, b) => a.id.length - b.id.length,
        },
        {
            title: 'Rol',
            dataIndex: 'type',
            width: 150,
            render: (_, record) => {
                console.log(record.type)
                return (record.type === 'adm' ? 'ADMIN' : 'USUARIO')
            },
        },
        {   
            title: 'Opción',
            key: 'id',
            width: 250,
            render: (_, record) => {
                return (
                    <>
                        <Space size="middle">
                            <Link to={"../usuario/editar/" + record.id}><Button size='small'>Editar</Button></Link>
                        </Space>
                    </>
                );
            },
        }
        

    ];


    const showTotalMessage = (total, range) => {
        
        let defaultMessage = `Mostrando ${range[0]}-${range[1]} de ${total} registros`;
        let filteredMessage = `${defaultMessage} que contienen "${search}". Resultados filtrados:  ${dataSource.length} registro.`;

        return search ? filteredMessage : defaultMessage;
    }
    
    return (
        <>
            <Table
                size="small"
                columns = {columns}
                dataSource = {dataSource}
                rowKey = 'id'
                scroll={{
                    x: "1000",
                }}
                tableLayout="auto"
                pagination={
                    {
                        total: totalRecords,
                        defaultCurrent: 1, 
                        showTotal: (total, range) => showTotalMessage(total, range),
                        showSizeChanger: true,
                        showQuickJumper: true
                    }
                }
            >
            </Table>
        </>
    );
}