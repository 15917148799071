import React, { useState, useEffect, } from 'react'
import { Link } from 'react-router-dom';
import { Table, Button, Space, Alert, message, Typography, Row, Form, Input, Pagination } from 'antd';
import { DeleteOutlined, EditOutlined, WarningFilled} from '@ant-design/icons';
import { Modal } from '../../UI/Modal/Modal.component';

const { Text, Paragraph } = Typography;

export const CustomerRedeemTable = ({data, search, setPagination, pagination, ...props}) => {

    const [dataSource, setDataSource] = useState(data.rows);
    const [totalRecords, setTotalRecords] = useState(data.count);
    const [filteredResults, setFilteredResults] = useState('');
    const [currentRow, setCurrentRow] = useState('');
    const [displayModal, setDisplayModal] = useState(false);  

    useEffect(() => {
        setDataSource(data.rows);
        setTotalRecords(data.count)
        console.log(data);
    }, [data.rows, data.count])


    const onShowSizeChange = (current, pageSize) => {
        const change = {
            current: current, 
            pageSize: pageSize,
        };        
        setPagination(change);
    };

    const onChange = (page, pageSize) => {
        const change = {
            current: page, 
            pageSize: pageSize,
        };
        window.scrollTo(0,0);
        setPagination(change);
    };

    const columns = [
        {
            title: 'Código',
            dataIndex: 'codigo',
            width: 50,
        },
        // {
        //     title: 'Cliente',
        //     dataIndex: 'cliente',
        //     width: 100,
        // },
        // {
        //     title: 'Planta',
        //     dataIndex: 'planta',
        //     width: 50,
        // },
        {
            title: 'Puntos Canjeados',
            dataIndex: 'canjeados',
            width: 50,
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            width: 50,
        },
        // {   
        //     title: 'Opción',
        //     key: 'id',
        //     width: 250,
        //     render: (_, record) => {
        //         return (
        //             <>
        //                 <Space size="middle">
        //                     <Link to={"../cliente/editar/" + record.id}><Button size='small'>Editar</Button></Link>
        //                 </Space>
        //             </>
        //         );
        //     },
        // }
        

    ];


    const showTotalMessage = (total, range) => {
        
        let defaultMessage = `Mostrando ${range[0]}-${range[1]} de ${total} registros`;
        let filteredMessage = `${defaultMessage} que contienen "${search}". Resultados filtrados:  ${totalRecords} registro.`;

        return search ? filteredMessage : defaultMessage;
    }
    
    return (
        <>
            <Table
                size="small"
                columns = {columns}
                dataSource = {dataSource}
                rowKey = 'id'
                scroll={{
                    x: "1000",
                }}
                tableLayout="auto"
                pagination= {false}
            >
            </Table>

            {pagination ? 
                <Row justify="end" style={{marginTop: 20}}>
                    <Pagination 
                        total={totalRecords}
                        defaultCurrent={pagination.current}
                        defaultPageSize={pagination.pageSize}
                        showSizeChanger
                        showQuickJumper
                        showTotal = {(total, range) => showTotalMessage(total, range)}
                        onChange={(page, pageSize) => onChange(page, pageSize)}
                    />
                </Row> 
            : ''}
            
        </>
    );
}