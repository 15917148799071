import axios from "axios";
import authHeader from "./auth-header";


const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : "http://localhost:5000/api/";


const getPromotions = (data) => {
  return axios.get(API_URL + "promotions", {headers: authHeader(), params: data});
};

const getPromotion = (data) => {
  return axios.get(API_URL + "promotions/" + data.id, {headers: authHeader(), params: data});
};

const save = (promotion) => {
  return axios
  .post(API_URL + "promotions/save", {
      ...promotion
  },)
  .then((response) => {
      return response
  });
}

const editPromotion = (promotion) => {
  return axios
  .post(API_URL + "promotions/" + promotion.id, {
      ...promotion
  },)
  .then((response) => {
      return response
  });
} 


const PromotionService = {
  getPromotions,
  getPromotion,
  editPromotion,
  save
};

export default PromotionService;
