import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : "http://localhost:5000/api/";


const getReedemReport = () => {
    return axios.get(API_URL + "reporte/canje", {headers: authHeader()});
};


const getReedemReportClients = (data) => {
    return axios.get(API_URL + "reporte/clientes", {headers: authHeader(), params: data});
};
const getVisitReport = (data) => {
    return axios.get(API_URL + "reporte/visitas", {headers: authHeader(), params: data});
};


const getInvoicesReport = (data) => {
    return axios.get(API_URL + "reporte/facturas", {headers: authHeader(), params: data});
};
  



const ReportService = {
    getReedemReport,
    getVisitReport,
    getInvoicesReport,
    getReedemReportClients
};

export default ReportService;
