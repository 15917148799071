import React, { useEffect, useState } from 'react';
import { Typography, Input, Row, Col, Button, Space, message } from 'antd';
import { Screen } from '../../components/UI/Screen.component';
import { Container } from '../../components/UI/Container.component';
import { ScreenHeader } from '../../components/UI/ScreenHeader.component';
import {useParams} from 'react-router-dom';
import { Section } from '../../components/UI/Section.component';
import { EditInformationForm } from '../../components/Dashboard/User/EditInformationForm';
import UserService from '../../services/user.service';
const { Title } = Typography;

export const EditUserAccountScreen = () => {

    const params = useParams();
    const [user, setUser] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    
    useEffect(() => {
        let data =  {id: params.id};
        console.log(data.id)
        UserService.getUser(data).then(
          (response) => {
            setUser(response.data.user);
            setIsLoaded(true);
          },
          (error) => {
              // console.log(error.response.data.message)
          }
        );
    }, [])


    return(
        <>
            <Screen id="">
                <Container >
                    <Section style={{width: '600px'}}>
                        <Row justify="space-between " style={{gap:"10px",}}>
                            <Col>
                                { isLoaded && <EditInformationForm user={user} />}
                            </Col>
                        </Row>
                    </Section>
                </Container>
            </Screen>
        </>
    )
}
