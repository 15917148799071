import React, {useEffect, useState} from 'react';
import { Form, Input, Select, Button, Row, message as notification, Typography  } from "antd";
import { UserAddOutlined, EyeTwoTone, EyeInvisibleOutlined} from '@ant-design/icons';
import { PasswordRequirements } from '../../UI/PasswordRequirements.component';
import UserService from '../../../services/user.service';

const { Option } = Select;

export const AddNewUserForm = ({ setChange, setVisible, ...props }) => {

    const [form] = Form.useForm();
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
    }, [])


    const onFinish = (data) => {       
        UserService.save(data).then(
            (response) => {
                notification.success("usuario agregado éxitosamente.")
                setVisible(false);
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handleChange = (value) => {
        form.setFieldsValue({ rol: value })
    };

    const onCancel = () => {
        form.resetFields();
        setChange(false);
    }

    return(
        <>
            <Form
                form={form}
                name='add-new-user-form'
                layout='vertical'
                // colon={true}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item 
                    hasFeedback
                    label='Usuario / Correo electrónico:' 
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                            type: 'text',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>
                <Form.Item
                        name="password"
                        label="Contraseña:"
                        rules={[
                            {
                                required: true,
                                message: ''
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    setNewPassword(value);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        hasFeedback
                        // help={ 
                        //         !isValidPassword(newPassword) && newPassword ? 
                        //             <PasswordRequirements password={newPassword}/>
                        //         : null
                        //         }
                        >
                        <Input.Password showCount allowClear iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Confirmar Contraseña:"
                    dependencies={['account-new-password']}
                    hasFeedback
                    rules={[
                        { 
                            required: true, 
                            message: "Campo requerido"
                        }, 
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if(!value || getFieldValue('password') === value){
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('La contraseña que acaba de ingresar no coincide con la contraseña ingresada anteriormente'))
                            }
                        }),
                    ]}
                    >
                    <Input.Password allowClear iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                </Form.Item>
                <Form.Item
                    name="rol"
                    // label=" Selecciona un Rol:"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                            type: 'text',
                        },
                    ]}
                    
                >
                    <Typography.Text>Rol: </Typography.Text>
                    <Select  onChange={handleChange} >
                        <Option value="usr">Usuario</Option>
                        <Option value="adm">Administrador</Option>
                    </Select>

                </Form.Item>
                <Form.Item>
                    <Row justify="end" style={{gap: 8}}>
                        <Button onClick={() => onCancel()}>Cancel</Button>
                        <Button type='primary' htmlType='submit' icon={<UserAddOutlined/>}>Guardar</Button>
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
}