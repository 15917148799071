import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';

export const Scanner = ({findClient}) => {
  
  return (
    <>
      <QrReader
        constraints={{
          facingMode: 'environment'
        }}
        onResult={(result, error) => {
          if (!!result) {
            findClient(result?.text);
          }

          if (!!error) {
            // console.info(error);
          }
        }}
        // style={{ width: '10%' }}
      />
      
    </>
  );
};