import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Space, Button, Avatar } from 'antd';
// useApp
import { MenuOutlined, DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import Logo from '../../../../src/assets/logo.jpg';
import moment from "moment";
import './NavBar.css';
import { useApp } from '../../../context/app-context';

export const NavBar = () => {
    const {state: {user}} = useApp();
    const [current, setCurrent] = useState('noc-dashboard');
    const AVAILABLE_DAYS = [2,5];

    const date = new Date();


   

    const collapseMenu = (<Button className="header__navbar--collapse" type="primary" icon={<MenuOutlined />}/>);
    const loggedUser = (<Space><p>{user.email ||'Cuenta' }</p><DownOutlined className="icon--DownOut"/></Space>);

    const menuItems = [
        { 
            label: (<Link to=''>Usuarios</Link>), 
            key: 'noc-dashboard',
            hidden: (user.type !== "adm")
        },
        { 
            label: (<Link to='clientes'>Clientes</Link>), 
            key: 'update-manager', 
            hidden: (user.type !== "adm")
        },
        { 
            label: (<Link to='plantas'>Plantas</Link>), 
            key: 'plantas', 
            hidden: (user.type !== "adm")
        },
        { 
            label: (<Link to='facturacion'>Facturar</Link>), 
            key: 'facturacion', 
        },
        { 
            label: (<Link to='canje'>Canje</Link>), 
            key: 'canje', 
            hidden: !AVAILABLE_DAYS.includes(moment(date).day())
        },
        { 
            label: (<Link to='promociones'>Promociones</Link>), 
            key: 'promociones', 
            hidden: (user.type !== "adm")
            // hidden: (user.userType !== "A")
        },
        { 
            label: 'Reportes',
            key: 'Reportes', 
            // icon: <Avatar size="small" icon={<UserOutlined />}/>,
            children: [
                // {
                //     label: (<Link to='reportes/canjes'>Reportes de Canje</Link>),
                //     key: 'reporte_canje',
                // },
                // {
                //     label: (<Link to='reportes/visitas'>Reporte de Facturas</Link>),
                //     key: 'reportes_visita',
                // },
                {
                    label: (<Link to='reportes/facturas'>Reporte de Facturas</Link>),
                    key: 'reportes_facturas',
                },
                {
                    label: (<Link to='reportes/canje/clientes'>Reporte de Canje por Cliente</Link>),
                    key: 'reportes_canje_clientes',
                },

            ],
        },
        // { 
        //     label: (<Link to='access-management'>Access Management</Link>), 
        //     key: 'access-management', 
        // },
    ];

    const accountMenuItems = [
        { 
            label: loggedUser,
            key: 'SubMenu', 
            icon: <Avatar size="small" icon={<UserOutlined />}/>,
            children: [
                {
                    label: (<Link to='account'>Mi perfil</Link>),
                    key: 'profile',
                },
                {
                    label: (<Link to='puntos'>Precio gasolina</Link>),
                    hidden: (user.type !== "adm"),
                    key: 'change-password',
                },

                {
                    type: 'divider'
                },
                {
                    label: (<Link to=''>Sign Out</Link>),
                    icon: <LogoutOutlined/>,
                    key: 'sign-out',
                    onClick: () => window.location.reload()
                },
            ],
        },
    ];

    const handleClick = e => {
        window.scrollTo(0, 0);
        setCurrent(e.key)
      }

    const clearHandler = () =>{
        setCurrent('noc-dashboard');
        // setIsAdvancedSearchVisible('inline-block');
    }

    return (
        <>
            <div className='navbar__container'>
                <div className='navbar'>
                    <div className="navbar__logo">
                        <Link to='#' onClick={clearHandler}><img src={Logo} alt="AvaLAN Networks logo" style={{pointerEvents: 'none'}}/></Link>
                    </div>
                    <div className='navbar__menu'>
                        <Menu 
                            className="navbar__menu--links" 
                            onClick={handleClick} 
                            selectedKeys={[ current ]} 
                            mode="horizontal"
                            overflowedIndicator={collapseMenu} 
                            items={menuItems} 
                            />
                    </div>
                    <Menu 
                        className="navbar__menu--account"
                        onClick={handleClick} 
                        selectedKeys={[ current ]} 
                        mode="horizontal"
                        items={accountMenuItems} 
                    />
                </div>
            </div>
        </>
    );
}