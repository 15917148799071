import React from 'react'

export const ScreenHeader = (props) => {
    
    const style = {
        padding: "30px 0",
        ...props.style, 
    }

    return (
        <>        
            <div 
                {...props}
                style={style} 
                className={props.className ? `screen__header ${props.className}` : `screen__header`} 
            >
                {props.children}
            </div>
        </>
    )
}
