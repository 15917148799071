import React, { useState, useEffect } from 'react'
import { Typography, Input, Row, Col, Button, Space, } from 'antd';
import { UserAddOutlined, SearchOutlined } from '@ant-design/icons';
import { Screen } from '../../components/UI/Screen.component';
import { Container } from '../../components/UI/Container.component';
import { ScreenHeader } from '../../components/UI/ScreenHeader.component';
import { Section } from '../../components/UI/Section.component';
import { Modal } from '../../components/UI/Modal/Modal.component';
import { BranchTable } from '../../components/Dashboard/Branch/BranchTable';
import ReportService from '../../services/report.service';
import { ReedemTable } from '../../components/Dashboard/Reports/ReedemTable';


const { Title } = Typography;


export const ReedemScreen = () => {

    const DEFAULT_PAGE = 1;
    const DEFAULT_PAGE_SIZE = 100;
    const [pagination, setPagination] = useState({current: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE});

    const [displayModal, setDisplayModal] = useState(false);
    const [dataSource, setDataSource] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [change, setChange] = useState(false);

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            return false;
        }
    }

    useEffect(() => {
        // let data = { limit: pagination.pageSize, page : pagination.current - 1, filter: search}
            ReportService.getReedemReport().then(
            (response) => {
                setDataSource(response.data.logs[0])
                setIsLoaded(true)
            },
            (error) => {
                // console.log(error.response.data.message)
            }
        );
    }, [])

    return(
        <>
            <Screen id="access-screen">
                <Container>
                    <ScreenHeader>
                        <Title>Reporte de Canjes</Title>
                    </ScreenHeader>
                    <Section>
                        { isLoaded && <ReedemTable data={dataSource}  setPagination={setPagination} pagination={pagination}/>}
                    </Section>
                </Container>
            
            </Screen>
        </>
    )
}