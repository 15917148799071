import { React, createContext} from 'react';
import { AppRouter } from './router/AppRouter';
import {AppProvider} from './context/app-context'
import './App.css';
import { BillingScreen } from './pages/Dashboard/BillingScreen';

function App() {
  return (
    <>
      <AppProvider>
        <AppRouter/>
      </AppProvider>
    </>
  );
}

export default App;