import React, {useEffect, useState} from 'react';
import { Form, Input, Select, Button, Row, message as notification, Typography, Radio } from "antd";
import { UserAddOutlined, EyeTwoTone, EyeInvisibleOutlined} from '@ant-design/icons';
import BranchService from '../../../services/branch.service';
const { Option } = Select;

export const AddNewPromotionForm = ({ setChange, setVisible, branches, ...props }) => {

    const [form] = Form.useForm();

    const selectStyle = {display: 'block', width:'100%',}

    const onFinish = (data) => {     
        console.log(data);  
        BranchService.save(data).then(
            (response) => {
                notification.success("Promoción agregada éxitosamente.")
                setVisible(false);
                setChange((current) => !current);
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handleChange = (value) => {
        form.setFieldsValue({ id_sucursal: value })
    };

    const onCancel = () => {
        form.resetFields();
        setChange(false);
    }

    return(
        <>
            <Form
                form={form}
                name='add-new-client-form'
                layout='vertical'
                colon={true}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item 
                    label="Nombre"
                    name="promocion"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='number'/>
                </Form.Item>
                <Form.Item 
                    label="Descripción"
                    name="descripcion"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>
                <Form.Item 
                    label="Valor puntos"
                    name="valor"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='number'/>
                </Form.Item>
                <Form.Item 
                    label="Estato promoción"
                    name="estatus"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Radio.Group>
                        <Radio value="1"> Activo </Radio>
                        <Radio value="0"> Inactivo </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item 
                    label="Cant. Mínima de Canje"
                    name="cant_min_canje"
                    hasFeedback
                    >
                    <Input type='text'/>
                </Form.Item>

                <Typography.Text>Sucursal</Typography.Text>
                <Form.Item 
                    // label="Sucursal"
                    name="id_sucursal"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Select  
                        onChange={handleChange} 
                        style={selectStyle} 
                    >
                        
                    </Select>
                
                </Form.Item>
                
                <Form.Item>
                    <Row justify="end" style={{gap: 8}}>
                        <Button onClick={() => onCancel()}>Cancel</Button>
                        <Button type='primary' htmlType='submit' icon={<UserAddOutlined/>}>Guardar</Button>
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
}