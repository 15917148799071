import React from 'react'
import './Layout.css'
import Logo from '../../../../src/assets/logo.jpg';
import { Link } from 'react-router-dom';
export const AuthLayout = () => {
    return (
        <>
            <div className="logo-container">
                <Link to=''><img src={Logo} className="logo"  width="250" alt="Coopegas" style={{pointerEvents: 'none'}}/></Link>
            </div>
        </>
    )
}
