import React, { useState, useEffect } from 'react'
import { Typography, Input, Row, Col, Button, Space, } from 'antd';
import { UserAddOutlined, SearchOutlined } from '@ant-design/icons';
import { Screen } from '../../components/UI/Screen.component';
import { Container } from '../../components/UI/Container.component';
import { ScreenHeader } from '../../components/UI/ScreenHeader.component';
import { Section } from '../../components/UI/Section.component';
import { Modal } from '../../components/UI/Modal/Modal.component';
import { ClientTable } from '../../components/Dashboard/Client/ClientTable';
import ClientService from '../../services/client.service';
import { AddNewClientForm } from '../../components/Dashboard/Client/AddNewClientForm';

// import { AddNewUserForm } from '../../components/Dashboard/AccessManagement/AddNewUserForm.component';

const { Title } = Typography;


export const ClientScreen = () => {

    const DEFAULT_PAGE = 1;
    const DEFAULT_PAGE_SIZE = 10;
    const [pagination, setPagination] = useState({current: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE});

    const [displayModal, setDisplayModal] = useState(false);
    const [search, setSearch] = useState('');
    const [dataSource, setDataSource] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [change, setChange] = useState(false);
    const [branches, setBranches] = useState([])

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            return false;
        }
    }

    useEffect(() => {
        let data = { limit: pagination.pageSize, page : pagination.current - 1, filter: search}
        ClientService.getClients(data).then(
            (response) => {
                setDataSource(response.data.clients)
                setBranches(response.data.branches)
                setIsLoaded(true)
            },
            (error) => {
                console.log(error)
            }
        );
    }, [search, pagination.current, pagination.pageSize])


    const clearBtnDisplay = search === '' ? 'none' : 'block';

    return(
        <>
            <Screen id="access-screen">
                <Container>
                    <ScreenHeader>
                        <Title>Administración de Clientes</Title>
                    </ScreenHeader>
                    <Section>
                        <Row justify="space-between" style={{marginBottom: "30px", gap:"30px",}}>
                            <Col>
                                <Space size="small">
                                    <p>Buscar:</p>
                                    <Input suffix={<SearchOutlined/>} placeholder="" allowClear value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <Button type='primary' style={{display: clearBtnDisplay}} onKeyDown={(e) => handleEnter(e)} onClick={() => setSearch('')}>Borrar</Button> 
                                </Space>
                            </Col>
                            <Col>
                                <Button onClick={() => setDisplayModal(true)} type="primary" icon={<UserAddOutlined />}>Nuevo usuario</Button>
                            </Col>
                        </Row>
                        { isLoaded && <ClientTable data={dataSource}  search={search} setPagination={setPagination} pagination={pagination}/>}
                    </Section>
                </Container>
                <Modal title='Agreagar cliente' visible={displayModal} setVisible={setDisplayModal} width={315} actionGroup={false}>
                    {/* <AddNewUserForm access={dataSource} visible={setDisplayModal} setChange={setChange} /> */}
                    { isLoaded && <AddNewClientForm setChange={setChange} branches={branches} setVisible={setDisplayModal}/> }
                </Modal>
            </Screen>
        </>
    )
}