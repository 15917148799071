import React, {useState, useEffect} from 'react'
import { Button, Form, Input, InputNumber, Typography, message } from 'antd';
import { ScreenHeader } from '../../UI/ScreenHeader.component';
import BranchService from '../../../services/branch.service';
const { Title } = Typography;
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

export const  EditBranchForm = ({branch, branchID}) => {

  const onFinish = (data) => {
    Object.assign(data, {id: branchID});
    console.log(data);
    BranchService.editBranch(data).then(
      (response) => {
        message.success("Cambios guardados correctamente");
      },
      (error) => {
          // console.log(error.response.data.message)
      }
    );
  };

  return (
    <Form 
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={branch}
    >
        <ScreenHeader style={{width: '400px;'}} >
            <Title>Editar planta</Title>
        </ScreenHeader>

      <Form.Item name='sucursal' label="Nombre Sucursal">
        <Input />
      </Form.Item>
      <Form.Item name='descripcion' label="Descripción">
        <Input />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 8,
        }}
      >
        <Button type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};