import React, {useEffect, useState} from 'react';
import { Form, Input, Select, Button, Row, message as notification, Typography  } from "antd";
import { UserAddOutlined, EyeTwoTone, EyeInvisibleOutlined} from '@ant-design/icons';
import ClientService from '../../../services/client.service';
const { Option } = Select;

export const AddNewClientForm = ({ setChange, branches, setVisible, ...props }) => {

    const [form] = Form.useForm();

    const selectStyle = {display: 'block', width:'100%',}


    const renderProfileOptions = (branches) => {
        const sortedBranches = branches?.sort((a, b) => a.sucursal.localeCompare(b.sucursal));
        return sortedBranches.map(branch => <Option key={branch.id} value={branch.id} >{branch.sucursal}</Option> );
    };

    const onFinish = (data) => {     
        ClientService.save(data).then(
            (response) => {
                notification.success("Cliente agregado éxitosamente.")
                setVisible(false);
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const handleChange = (value) => {
        form.setFieldsValue({ id_sucursal: value })
    };

    const onCancel = () => {
        form.resetFields();
        setChange(false);
    }

    return(
        <>
            <Form
                form={form}
                name='add-new-client-form'
                layout='vertical'
                colon={true}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item 
                    label="Código"
                    name="codigo"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='number'/>
                </Form.Item>
                <Form.Item 
                    label="Cédula"
                    name="cedula"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>
                <Form.Item 
                    label="Nombre"
                    name="nombre"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>
                <Form.Item 
                    label="Apellido"
                    name="apellido"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>

                <Form.Item 
                    label="Teléfono"
                    name="telefono"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>

                <Form.Item 
                    label="No. Placa"
                    name="placa"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>

                <Form.Item 
                    label="Vehiculo"
                    name="vehiculo"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>
                

                <Form.Item 
                    label="Tipo Vehiculo"
                    name="tipo_vehiculo"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >
                    <Input type='text'/>
                </Form.Item>

                <Typography.Text>Sucursal</Typography.Text>
                <Form.Item 
                    // label="Sucursal"
                    name="id_sucursal"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    >

                    <Select  onChange={handleChange} style={selectStyle} >
                        {renderProfileOptions(branches)}                        
                    </Select>
                
                </Form.Item>
                
                <Form.Item>
                    <Row justify="end" style={{gap: 8}}>
                        <Button onClick={() => onCancel()}>Cancel</Button>
                        <Button type='primary' htmlType='submit' icon={<UserAddOutlined/>}>Guardar</Button>
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
}