import axios from "axios";
import authHeader from "./auth-header";


const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : "http://localhost:5000/api/";

const save = (billing) => {
  return axios
  .post(API_URL + "billing/save", {
      ...billing, headers: authHeader(),
  },)
  .then((response) => {
      return response
  });
}


const reedem = (reedem) => {
  return axios
  .post(API_URL + "billing/reedem", {
      ...reedem, headers: authHeader()
  },)
  .then((response) => {
      return response
  });
}


const getInvoice = (data) => {
  return axios.get(API_URL + "billing/print/" + data.id, {headers: authHeader(), params: data});
};

const getReedem = (data) => {
  return axios.get(API_URL + "billing/reedem/" + data.id, {headers: authHeader(), params: data});
};




const BillingService = {
  save,
  getInvoice,
  reedem,
  getReedem
};

export default BillingService;
