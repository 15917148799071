import React from "react";
import ReactDOM from "react-dom";
// import 'antd/dist/antd.css';
import "../src/style/custom-antd.css" // overriden styles
import { BrowserRouter } from "react-router-dom";

import App from "./App";
// import * as serviceWorker from "./serviceWorker";
window.process = {};

ReactDOM.render(
  
  <BrowserRouter>
    <App />
  </BrowserRouter>,

  document.getElementById("root")
);

// serviceWorker.unregister();