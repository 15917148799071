import React, { } from 'react';

import { Navigate } from 'react-router-dom';
import { useApp } from '../context/app-context';

export const PublicRoute = ({ children }) => {

    const {state: {auth, user}} = useApp();
    
    const route = (user.type === 'adm' ? '/app' : "/app/facturacion")

    return auth
        ? <Navigate to={route} />
        : children
}