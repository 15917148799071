import React, { } from 'react';

import { Navigate } from 'react-router-dom';
import { useApp } from '../context/app-context';

export const PrivateRoute = ({ children }) => {

    const {state: {auth}} = useApp();
    
    return auth
        ? children
        : <Navigate to="/" />
}