import * as React from 'react'

const AppContext = React.createContext()


function appReducer(state, action) {
    
    switch (action.type) {
      case 'login':
      case 'logout': {
        return {
          ...state,
          auth: action.payload
        }
      }
      case 'update-user': {
        return {
          ...state,
          user: {
            ...action.payload
          }
        }
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
}

function AppProvider({children}) {
    // Learn more in http://kcd.im/optimize-context
    const [state, dispatch] = React.useReducer(appReducer, {app: {}, user: {}, auth: false})
    const value = {state, dispatch}
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

function useApp() {
    const context = React.useContext(AppContext)
    if (context === undefined) {
      throw new Error('useApp must be used within a AppProvider')
    }
    return context
}
  
export {AppProvider, useApp}