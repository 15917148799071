import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : "http://localhost:5000/api/";


const getPoint = () => {
    return axios.get(API_URL + "getPoint", {headers: authHeader()});
};


const update = (point) => {
    return axios
    .post(API_URL + "point/update", {
        point
    },)
    .then((response) => {
        // console.log(response)
    });
}

const PointService = {
    getPoint,
    update
};

export default PointService;
