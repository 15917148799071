import React, { useState, useEffect } from 'react'
import { Typography, Input, Row, Col, Button, Space, DatePicker, Form} from 'antd';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { Screen } from '../../components/UI/Screen.component';
import { Container } from '../../components/UI/Container.component';
import { ScreenHeader } from '../../components/UI/ScreenHeader.component';
import { Section } from '../../components/UI/Section.component';
import ReportService from '../../services/report.service';
import moment from "moment";
import { InvoiceTable } from '../../components/Dashboard/Reports/InvoiceTable';

const { RangePicker } = DatePicker;

// import { AddNewUserForm } from '../../components/Dashboard/AccessManagement/AddNewUserForm.component';

const { Title } = Typography;


export const InvoiceScreen = () => {

    const DEFAULT_PAGE = 1;
    const DEFAULT_PAGE_SIZE = 10;
    const [pagination, setPagination] = useState({current: DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE});
    const [dates, setDates] = useState({from: moment().format('YYYY-MM-DD') , to: moment().add(1, 'days').format('YYYY-MM-DD')});

    const [displayModal, setDisplayModal] = useState(false);
    const [search, setSearch] = useState('');
    const [dataSource, setDataSource] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);
    const [tarjeta, setTarjeta] = useState(0);
    const [efectivo, setEfectivo] = useState(0);
    
    const [change, setChange] = useState(false);
    const [branches, setBranches] = useState([])

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            return false;
        }
    }

    useEffect(() => {
        let data = { limit: pagination.pageSize, page : pagination.current - 1, filter: search, from: dates.from, to: dates.to}
        ReportService.getInvoicesReport(data).then(
            (response) => {
                console.log(response);
                setDataSource(response.data.logs)
                let record = response.data.logs[0]
                if(record) {
                    setEfectivo(Number(record.total_efectivo) * Number(record.precio_gasolina) )
                    setTarjeta(Number(record.total_tarjeta) * Number(record.precio_gasolina) )
                }
                // setBranches(response.data.branches)
                setIsLoaded(true)
            },
            (error) => {
                console.log(error)
            }
        );
    }, [search, dates, pagination.current, pagination.pageSize])


    const clearBtnDisplay = search === '' ? 'none' : 'block';

    return(
        <>
            <Screen id="access-screen">
                <Container>
                    <ScreenHeader>
                        <Title>Reportes de Facturas</Title>
                    </ScreenHeader>
                    <Section>
                        <Row justify="space-between" style={{marginBottom: "30px", gap:"30px",}}>
                            <Col>
                                <Space size="small">
                                    <p>Codigo:</p>
                                    <Input suffix={<SearchOutlined/>} placeholder="" allowClear value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <RangePicker  defaultValue={[moment(moment() , 'YYYY-MM-DD'), moment(moment().add(1, 'days'), 'YYYY-MM-DD')]}  format={'YYYY-MM-DD'} onChange={(date, dateString) => setDates({from: dateString[0], to: dateString[1]}) } />
                                    <Button type='primary' style={{display: clearBtnDisplay}} onKeyDown={(e) => handleEnter(e)} onClick={() => setSearch('')}>Borrar</Button>
                                </Space>
                            </Col>

                        </Row>
                        <Row justify={'center'}>
                            <Col>
                                {/* <Space> */}
                                    <p>Total Efectivo:</p>
                                    <Input value={efectivo.toFixed(2)} placeholder="" disabled={true} />
                                    <p>Total Tarjeta:</p>
                                    <Input value={tarjeta.toFixed(2)} placeholder="" disabled={true}  /> 
                                {/* </Space> */}
                            </Col>
                        </Row>    
                        { isLoaded && <InvoiceTable data={dataSource}  search={search} setPagination={setPagination} pagination={pagination}/>}
                    </Section>
                </Container>
               
            </Screen>
        </>
    )
}