import React from 'react'

export const Screen = (props) => {

    const style = {
        padding: 'var(--navbar-height) 0 30px 0',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        minHeight: '-webkit-fill-available',
        overflow: 'auto',
        backgroundColor: 'var(--screen-bg)',
        ...props.style,
    }

    return (
        <>        
            <div className='screen' style={style} {...props}>
                {props.children}
            </div>
        </>
    )
}
