import React, { useState, useEffect, } from 'react'
import { Link } from 'react-router-dom';
import { Table, Button, Space, Alert, message, Typography, Row, Form, Input, Pagination } from 'antd';
import { DeleteOutlined, EditOutlined, WarningFilled} from '@ant-design/icons';
import { Modal } from '../../UI/Modal/Modal.component';
import { useApp } from '../../../context/app-context';


const { Text, Paragraph } = Typography;

export const InvoiceTable = ({data, search, setPagination, pagination, ...props}) => {

    const {state: {user}} = useApp();
    const [dataSource, setDataSource] = useState(data);
    const [totalRecords, setTotalRecords] = useState( (data[0]) ? data[0].count : 0);
    const [filteredResults, setFilteredResults] = useState('');
    const [currentRow, setCurrentRow] = useState('');
    const [displayModal, setDisplayModal] = useState(false);  

    useEffect(() => {
        setDataSource(data);
        setTotalRecords( (data[0]) ? data[0].count : 0)
    }, [data])


    const onShowSizeChange = (current, pageSize) => {
        const change = {
            current: current, 
            pageSize: pageSize,
        };        
        setPagination(change);
    };

    const onChange = (page, pageSize) => {
        const change = {
            current: page, 
            pageSize: pageSize,
        };
        window.scrollTo(0,0);
        setPagination(change);
    };

    const columns = [
        {
            title: 'Código',
            dataIndex: 'codigo',
            width: 50,
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            width: 100,
        },
        {
            title: 'Método de Pago',
            dataIndex: 'metodo_pago',
            width: 50,
        },
        {
            title: 'Planta',
            dataIndex: 'planta',
            width: 50,
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            width: 50,
        },
        {
            title: 'Total',
            dataIndex: 'cantidad_galones',
            width: 100,
            render: (_, record ) => {
                return (Number(record.cantidad_galones) * Number(record.precio_gasolina) ).toFixed(2);
            }
        },
        {   
            title: 'Factura',
            key: 'id',
            width: 250,
            render: (_, record) => {
                return (
                    <>
                        { user.type === 'adm' &&
                            <Space size="middle">
                                <Link to={"/print/factura/" + record.factura_id}><Button size='small'>Imprimir</Button></Link>
                            </Space>
                        }
                    </>
                );
            },
        }
        

    ];


    const showTotalMessage = (total, range) => {
        
        let defaultMessage = `Mostrando ${range[0]}-${range[1]} de ${total} registros`;
        let filteredMessage = `${defaultMessage} que contienen "${search}". Resultados filtrados:  ${dataSource.length} registro.`;

        return search ? filteredMessage : defaultMessage;
    }
    
    return (
        <>
            <Table
                size="small"
                columns = {columns}
                dataSource = {dataSource}
                rowKey = 'id'
                scroll={{
                    x: "1000",
                }}
                tableLayout="auto"
                pagination= {false}
            >
            </Table>

            {pagination ? 
                <Row justify="end" style={{marginTop: 20}}>
                    <Pagination 
                        total={totalRecords}
                        defaultCurrent={pagination.current}
                        defaultPageSize={pagination.pageSize}
                        showSizeChanger
                        showQuickJumper
                        showTotal = {(total, range) => showTotalMessage(total, range)}
                        onChange={(page, pageSize) => onChange(page, pageSize)}
                    />
                </Row> 
            : ''}
            
        </>
    );
}